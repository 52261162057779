<template>
  <div v-for="tenant in split" class="carousel__slide cursor-pointer" @click="$emit('tenantclick', tenant)">
    <div class="carousel__container">
      <el-image class="carousel__image" :src="`${url}/assets/${tenant.images[0]?.directus_files_id}?key=webp-thumbnail`" alt="" fit="contain"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';

const props = defineProps<{
  split: any[],
}>()

const url = import.meta.env.PUBLIC_API_URL;

const noslides = computed<number>(() => {
  return props.split.length;
})


</script>

<style lang="scss">
@media (min-width: 320px) and (max-width: 767px) {
  :root {
    --slide-width: 8.4375rem;
    --slide-height: 5.625rem;
  }
}
@media (min-width: 768px) and (max-width: 2199px) {
  :root {
    --slide-width: 11.25rem;
    --slide-height: 7.5rem;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .shifted {
    transform: translate3d(-4.21875rem, 0, 0)
  }
}
@media (min-width: 768px) and (max-width: 2199px) {
  .shifted {
    transform: translate3d(-5.625rem, 0, 0)
  }
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc((var(--slide-width) + 10px) * v-bind(noslides) * -1), 0, 0);
  }
}

.carousel__slide {
  @apply justify-center items-center;
  will-change: transform;
  animation: scroll calc(v-bind('noslides') * 2s) linear infinite;
  display: flex;
  flex-direction: column;
  margin: 5px 5px;

  flex: 0 0 auto;
  width: var(--slide-width);
  height: var(--slide-height);
  box-sizing: border-box;
  &:hover {
    filter: brightness(75%);
  }

  /*border: 1px dotted darkblue;*/
}

.carousel__image{
  @apply flex w-full object-cover;
}

.carousel__container {
  @apply flex bg-white items-center justify-center overflow-hidden;
  height: var(--slide-height);
  width: var(--slide-width);
  border-radius: 12px;
}
</style>
