 <template>
  <div class="col-span-12 w-full text-center pt-4 pb-8" v-if="tenants.length != 0">
    <div class="mt-10 sm:mt-8"></div>
    <Divider bgColor="#E5E7EB" :text="tenants?.[0]?.[0]?.type.name" extraClass="sm:text-[20px]"/>
    <div class="sm:pt-4"></div>
    <div v-if="highlight_tenants.length != 0" class="pt-12 sm:pt-4 pb-4">
      <div class="text-[2rem] sm:text-[1.25rem] italic font-semibold pb-4">Highlight Tenants
      </div>
      <div v-for="talents in highlight_tenants" class="sm:hidden flex justify-center gap-[1rem] pt-2">
        <div class="pt-1" v-for="talent in talents">
          <div class="bg-white p-3 cursor-pointer w-[16vw] h-[13.2rem]" @click="clickTenant(talent)">
            <img class="w-full h-[10rem] object-cover"
                 :src="`${url}/assets/${talent.images[0].directus_files_id}?key=webp`" alt=""/>
            <div class="pt-3 pb-2 w-full text-center font-bold">{{ talent.name }}</div>
          </div>
        </div>
      </div>
      <div v-for="talents in highlight_tenants_mobile"  class="md:hidden flex justify-center gap-[1rem] pt-4">
        <div v-for="talent in talents"  class="bg-white p-3 w-[40%] cursor-pointer  h-[12rem]" @click="clickTenant(talent)">
          <div>
            <img class="w-full h-[9rem] object-cover" :src="`${url}/assets/${talent.images[0].directus_files_id}?key=webp`" alt=""/>
            <div class="pt-3 pb-2 w-full text-center font-bold">{{ talent.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="convoy_tenants.length != 0" class="pt-12 pb-4">
      <div class="text-[2rem] sm:text-[1.25rem] italic font-semibold pb-4">Cook Convoy</div>
      <div v-for="talents in convoy_tenants" class="sm:hidden flex justify-center gap-[1rem] pt-2">
        <div class="pt-1" v-for="talent in talents">
          <div class="bg-white p-3 cursor-pointer w-[16vw] h-[13.2rem]" @click="clickTenant(talent)">
            <img class="w-full h-[10rem] object-cover"
                 :src="`${url}/assets/${talent.images[0].directus_files_id}?key=webp`" alt=""/>
            <div class="pt-3 pb-2 w-full text-center font-bold">{{ talent.name }}</div>
          </div>
        </div>
      </div>
      <div v-for="talents in convoy_tenants_mobile"  class="md:hidden flex justify-center gap-[1rem] pt-4">
        <div v-for="talent in talents"  class="bg-white p-3 w-[40%] cursor-pointer  h-[12rem]" @click="clickTenant(talent)">
          <div>
            <img class="w-full h-[9rem] object-cover" :src="`${url}/assets/${talent.images[0].directus_files_id}?key=webp`" alt=""/>
            <div class="pt-3 pb-2 w-full text-center font-bold">{{ talent.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="convoy_tenants.length != 0 || highlight_tenants.length != 0">
      <div class="text-[2rem] sm:text-[1.25rem] italic font-semibold pt-12">Spotlight Tenants</div>
    </div>
    <div class="tenant container-fluid px-0 pt-8">
      <div class="row">
        <div ref="topcarousel" class="topcarousel col-12" style="overflow: hidden;">
          <div v-for="(split, i) in tenants" class="carousel__wrapper" :class="{'shifted': i % 2 == 0}">
            <!-- Loop thrice? -->
            <CarouselItem :split="split" @tenantclick="clickTenant"/>
            <CarouselItem :split="split" @tenantclick="clickTenant"/>
            <CarouselItem :split="split" @tenantclick="clickTenant"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import { useGlobalState } from '../../../utils/store'
import Divider from "../../utils/Divider.vue";
import CarouselItem from "./CarouselItem.vue";

const props = defineProps<{
  event: any,
  tenants: any,
  highlight_tenants: any,
  highlight_tenants_mobile: any,
  convoy_tenants: any,
  convoy_tenants_mobile: any,
}>()

const url = import.meta.env.PUBLIC_API_URL;
const topcarousel = ref(null);

const tenants = props.tenants;

const state = useGlobalState();

watch(state.isOpen, () => {
  if (topcarousel.value) {
    if (state.isOpen.value == true) {
      topcarousel.value.classList.add('paused');
    } else {
      topcarousel.value.classList.remove('paused');
    }
  }
})

const clickTenant = (tenant: any) => {
  state.isOpen.value = true;
  state.name.value = tenant.name;
  state.description.value = tenant.description;
  state.images.value = tenant.images;
  state.type.value = "tenant";
  state.instagram.value = tenant.idLink;
  window.dialog.showModal();
}

</script>

<style lang="scss">
  .carousel__wrapper {
    display: flex;
    /*justify-content: center;*/
    align-items: center;

    width: 100vw;
    margin: 0 auto;
  }

  .paused div div {
    animation-play-state: paused;
  }

  .topcarousel {
    &:hover div div {
      animation-play-state: paused;
    }
  }

  .carousel {
    overflow: hidden;
  }

  .dash {
    letter-spacing: -8pt;
  }
</style>
